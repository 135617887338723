import React from "react";
import {
  FaPhone,
  FaEnvelope,
  FaInstagram,
  FaFacebook,
  FaTwitter,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { FaArrowUp } from "react-icons/fa";
import "./footer.css";
function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Ensures a smooth scrolling effect
    });
  };
  return (
    <footer
      className="bg-gradient-to-r from-blue-900 via-purple-900 to-red-900  text-white h-auto relative"
      style={{
        backgroundImage: `linear-gradient(255.34deg, #310303 2.94%, #001F40 105.06%), url(${"https://squarebric-media-files.squarebric.com/sivaldevops/assets/bg.png"})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="bg-footer py-10">
        <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-4 gap-8 max-w-7xl w-full ">
          {/* Logo and Description Section */}
          <div className="flex flex-col space-y-4 bg-[#FFFFFF] rounded-[20px] h-auto">
            <LazyLoadImage
              src="https://squarebric-media-files.squarebric.com/sivaldevops/assets/mainlogo.png"
              alt="Sival DevOps"
              className="h-20 w-auto rounded-[20px]"
            />
            <p className="text-[#1A294F] m-5 font-[400] text-justify ">
              Sival Devops is a leading provider of innovative business software
              solutions, information technology, and consulting services.
            </p>
          </div>
          <div className="flex justify-between md:p-0 p-2 md:hidden ">
            {/* Company Section */}
            <div className="flex flex-col space-y-3">
              <h4 className="font-semibold">Company</h4>
              <Link
                to="/contact-us"
                className="text-gray-400 hover:text-gray-200"
              >
                Contact Us
              </Link>

              <Link
                to="/about-us"
                className="text-gray-400 hover:text-gray-200"
              >
                About Us
              </Link>
              <Link to="#" className="text-gray-400 hover:text-gray-200">
                Blog & News
              </Link>
            </div>

            {/* Quick Links Section */}
            <div className="flex flex-col space-y-3">
              <h4 className="font-semibold">Quick Links</h4>
              <Link to="/careers" className="text-gray-400 hover:text-gray-200">
                Careers
              </Link>
              <Link to="#" className="text-gray-400 hover:text-gray-200">
                Free Demo
              </Link>
              <Link to="#" className="text-gray-400 hover:text-gray-200">
                Press Conferences
              </Link>
            </div>
          </div>

          {/* Company Section */}

          <div className="md:block hidden">
            <div className="flex flex-col space-y-3">
              <h4 className="font-semibold">Company</h4>
              <Link
                to="/contact-us"
                className="text-gray-400 hover:text-gray-200"
              >
                Contact Us
              </Link>

              <Link
                to="/about-us"
                className="text-gray-400 hover:text-gray-200"
              >
                About Us
              </Link>
              <Link to="#" className="text-gray-400 hover:text-gray-200">
                Blog & News
              </Link>
            </div>
          </div>

          {/* Quick Links Section */}
          <div className="md:block hidden">
            <div className="flex flex-col space-y-3">
              <h4 className="font-semibold">Quick Links</h4>
              <Link to="/careers" className="text-gray-400 hover:text-gray-200">
                Careers
              </Link>
              <Link to="#" className="text-gray-400 hover:text-gray-200">
                Free Demo
              </Link>
              <Link to="#" className="text-gray-400 hover:text-gray-200">
                Press Conferences
              </Link>
            </div>
          </div>
          {/* Reach Out Section */}
          <div className="flex flex-col space-y-3 md:p-0 p-2">
            <h4 className="font-semibold">Reach Out</h4>
            <div className="flex items-center space-x-2">
              <FaPhone className="text-gray-400 " />
              <span className="text-gray-400 hover:text-gray-200">
              +91-9538588333
              </span>
            </div>
            <div className="flex items-center  space-x-2">
              <FaEnvelope className="text-gray-400 hover:text-gray-200" />
              <span className="text-gray-400 hover:text-gray-200">
                info@sivaldevops.com
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <p className="text-gray-400 capitalize hover:text-gray-200">
                Sival Devops <br />
                15, 2nd Cross Road, Hig Colony, RMV 2nd Stage Dollars Colony
                Bangalore 560094, Karnatka, India
              </p>
            </div>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="border-t border-gray-600 mt-8 py-4 ">
          <div className="container mx-auto flex flex-col md:flex-row items-center justify-between max-w-7xl w-full px-4">
            <p className="text-gray-400">Copyright © 2024 - Sival Devops</p>
            <div className="flex space-x-4 mt-4 md:mt-0">
              <Link href="#" className="text-gray-400 hover:text-gray-200">
                <FaInstagram size={20} />
              </Link>
              <Link href="#" className="text-gray-400 hover:text-gray-200">
                <FaFacebook size={20} />
              </Link>
              <Link href="#" className="text-gray-400 hover:text-gray-200">
                <FaTwitter size={20} />
              </Link>
            </div>
          </div>
        </div>
        <div
          onClick={scrollToTop}
          className="absolute right-10 top-[-10px] w-12 h-12 rounded-[50%] bg-white flex items-center justify-center"
        >
          <button className="text-black animate-blink">
            <FaArrowUp />
          </button>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
