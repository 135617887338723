import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
const untend1 = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 5000,
      behavior: "smooth",
    });
  };
  return (
    <div className="relative w-full h-[15rem] xs:h-[28rem] sm:h-[30rem] md:h-[35rem] lg:h-[42rem] overflow-hidden">
      <LazyLoadImage
        src="https://squarebric-media-files.squarebric.com/sivaldevops/assets/untend.png"
        alt="smokedetection"
        className="absolute top-0 left-0 object-cover w-full h-full" // Changed to object-cover for better fit
      />

      {/* Content positioned on top of the image */}
      <div className="relative flex flex-col items-center justify-center h-full p-4 text-center">
        <h1 className="mb-4 text-lg font-bold text-white xs:text-xl sm:text-2xl md:text-3xl lg:text-4xl">
          Unattended Object Detection
        </h1>
        <p className="mt-2 leading-6 sm:leading-7 md:leading-8 text-center text-[16px] sm:text-[18px] md:text-[20px] font-[300] text-white md:w-1/2">


          Object detection uses AI to identify and locate objects like bags,
          suitcases, and boxes in images or video streams, ensuring accurate
          monitoring for security purposes. Sival DevOps offers advanced
          solutions for detecting unattended and moving objects efficiently.
        </p>
        <button
          onClick={handleScrollToTop}
          className=" text-white border-2 border-white text-[20px] p-1 hover:text-[#2e4374] hover:bg-white w-[150px] rounded-3xl mt-5 transition transform hover:translate-y-2"
        >
          Book Demo
        </button>
      </div>
    </div>
  );
};

export default untend1;
