import React from 'react';

const JobRequirements = () => {
  const qualifications = [
    "Bachelor's degree in Computer Science, Information Technology, or a related field.",
    "Minimum of 2 years of experience in web development or related operations.",
    "Strong proficiency in debugging, troubleshooting, and problem-solving in web applications.",
    "Knowledge of web accessibility standards and security best practices.",
    "Familiarity with regulatory requirements for data privacy and compliance in web development.",
    "Commitment to delivering high-quality, user-focused web solutions."
  ];

  const competencies = [
    "Foster positive collaboration and communication within development teams.",
    "Exhibit ownership, commitment, respect, and a strong sense of teamwork in all projects.",
    "Demonstrate flexibility and adaptability in fast-paced and dynamic development environments.",
    "Possess strong verbal and written communication skills for effective stakeholder engagement.",
    "Showcase excellent analytical, debugging, and decision-making skills to solve complex technical challenges."
  ];

  return (
    <div className='' style={{
      background: "linear-gradient(to left, #310303 0%, #001F40 100%)",
     
    }}>
    <div className="max-w-7xl mx-auto p-6  rounded-xl shadow-2xl ">
      <div className="grid md:grid-cols-2 gap-10">
        {/* Qualifications Section */}
        <div className="space-y-6">
          <h2 className="text-2xl font-bold text-white border-b border-red-500 pb-2 mb-4">
            Qualifications
          </h2>
          <ul className="space-y-4">
            {qualifications.map((item, index) => (
              <li key={index} className="flex items-start space-x-3">
                <div className="flex-shrink-0 w-1.5 h-1.5 mt-2 rounded-full bg-red-500"></div>
                <span className="text-gray-300">{item}</span>
              </li>
            ))}
          </ul>
        </div>

        {/* Behavioral Competencies Section */}
        <div className="space-y-6">
          <h2 className="text-2xl font-bold text-white border-b border-red-500 pb-2 mb-4">
            Behavioral Competencies
          </h2>
          <ul className="space-y-4">
            {competencies.map((item, index) => (
              <li key={index} className="flex items-start space-x-3">
                <div className="flex-shrink-0 w-1.5 h-1.5 mt-2 rounded-full bg-red-500"></div>
                <span className="text-gray-300">{item}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
    </div>
  );
};

export default JobRequirements;