import React from "react";
import jobweb from "../../../assets/jobdeveloper.png";
import loc from "../.../../../../assets/loc.png";
import Exp from "../.../../../../assets/Exp.png";
import hour from "../.../../../../assets/hour.png";
import jobtype from "../.../../../../assets/jobtype.png";
import Vacancy from "../.../../../../assets/vacancy.png";
import working from "../.../../../../assets/workingdays.png";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
const JobPosting = () => {
  const responsibilities = [
    "Design and implement efficient workflows for front-end and back-end development.",
    "Manage code versioning and deployment processes.",
    "Ensure alignment of project deliverables with development plans and timelines.",
    "Conduct effective resource allocation for development tasks.",
    "Adhere to coding standards and best practices for all development activities.",
    "Maintain accurate documentation of development processes and compliance requirements.",
    "Ensure adherence to web accessibility, security, and performance standards.",
    "Develop and implement strategies for website optimization and troubleshooting.",
    "Collaborate with stakeholders to address technical challenges and requirements.",
    "Manage database integration and data retrieval processes effectively.",
  ];

  const jobDetails = [
    { icon: loc, label: "Location", value: "Work from Office" },
    { icon: jobtype, label: "Job Type", value: "Full Time" },
    { icon: Exp, label: "Experience", value: "Experience: 1-3 year" },
    { icon: Vacancy, label: "Vacancy", value: "No of Vacancy: 1" },
    { icon: hour, label: "Working Hours", value: "10AM - 5:30 PM" },
    { icon: working, label: "Working Days", value: "Weekly: 5days" },
  ];

  return (
    <div
      className=""
      style={{
        background: "linear-gradient(to left, #310303 0%, #001F40 100%)",
      }}
    >
      <div className="h-auto  p-8 text-white">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-4xl font-bold mb-6">WEB DEVELOPER</h1>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Job Purpose Section */}
            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4 border-b border-red-500 pb-2 inline-block">
                Job Purpose
              </h2>
              <p className="text-gray-300">
                To manage web development operations and gain a deep
                understanding of our global web applications.
              </p>
            </section>

            {/* Image Section */}
            <section className="flex justify-center items-center">
              <LazyLoadImage 
                src={jobweb}
                alt="dynamic"
                className="w-full h-auto max-w-md object-cover rounded-lg shadow-lg"
              />
            </section>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="sm:mt-[0px] md:mt-[-70px]">
              <h2 className="text-2xl font-semibold mb-4 border-b border-red-500 pb-2 inline-block">
                Key Responsibilities
              </h2>
              <ul className="space-y-3">
                {responsibilities.map((responsibility, index) => (
                  <li key={index} className="text-gray-300 flex items-start">
                    <span className="mr-2">•</span>
                    {responsibility}
                  </li>
                ))}
              </ul>
            </div>

            <div className="bg-gray-800/50 rounded-xl p-6 backdrop-blur-sm mt-5">
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-xl font-semibold">Job Summary</h3>
                <button className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700 transition">
                  Apply Now
                </button>
              </div>

              <div className="grid grid-cols-2 gap-4 ">
                {jobDetails.map((detail, index) => (
                  <div key={index} className="flex items-center space-x-3">
                    <LazyLoadImage  src={detail.icon} className="" />
                    <div>
                      <p className="text-gray-400 text-sm">{detail.label}</p>
                      <p className="text-white">{detail.value}</p>
                    </div>
                  </div>
                ))}
              </div>

              <div className="mt-6 pt-6 border-t border-gray-700">
                <p className="text-gray-400 mb-3">Share with Others</p>
                <div className="flex space-x-3">
                  <FaFacebookF />
                  <FaTwitter />
                  <FaLinkedin />
                  <FaInstagram />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobPosting;