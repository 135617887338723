import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";


function Header() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen((prev) => !prev);
  };



  return (
    <header className="fixed top-0 left-0 w-full z-50 bg-white shadow-md">
      <div className="max-w-7xl mx-auto py-3">
        <div className="flex items-center justify-between px-4">
          {/* Logo Section */}
          <div className="flex items-center">
            <Link to="/" className="text-gray-700 hover:text-red-700">
              <LazyLoadImage
                src="https://squarebric-media-files.squarebric.com/sivaldevops/assets/mainlogo.png"
                alt="Sival DevOps"
                className="h-12"
              />
            </Link>
          </div>

          {/* Navigation Links */}
          <div>
            {/* Desktop Navigation */}
            <nav className="hidden lg:flex space-x-10 items-center">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive
                    ? "text-red-700 font-bold relative inline-block group"
                    : "text-gray-700 hover:text-red-700 relative inline-block group"
                }
              >
                Home
                <span className="absolute bottom-0 left-0 w-0 h-[2px] bg-red-700 transition-all duration-300 ease-in-out group-hover:w-full"></span>
              </NavLink>

              <NavLink
                to="/oursolution"
                className={({ isActive }) =>
                  isActive
                    ? "text-red-700 font-bold relative inline-block group"
                    : "text-gray-700 hover:text-red-700 relative inline-block group"
                }
              >
                Our Solution
                <span className="absolute bottom-0 left-0 w-0 h-[2px] bg-red-700 transition-all duration-300 ease-in-out group-hover:w-full"></span>
              </NavLink>

              <NavLink
                to="/squarebric"
                className={({ isActive }) =>
                  isActive
                    ? "text-red-700 font-bold relative inline-block group"
                    : "text-gray-700 hover:text-red-700 relative inline-block group"
                }
              >
                Our Squarebric
                <span className="absolute bottom-0 left-0 w-0 h-[2px] bg-red-700 transition-all duration-300 ease-in-out group-hover:w-full"></span>
              </NavLink>

              <NavLink
                to="/contact-us"
                className={({ isActive }) =>
                  isActive
                    ? "bg-red-600 text-white px-4 py-2 font-[500] rounded-full"
                    : "bg-red-700 text-white px-4 py-2 font-[500] rounded-full hover:text-white hover:bg-red-600  transition-colors duration-300 ease-in-out"
                }
              >
                Contact Us
              </NavLink>
            </nav>
          </div>

          {/* Mobile Navigation - Hamburger Menu */}
          <div className="lg:hidden">
            <button
              onClick={toggleMobileMenu}
              className="text-gray-700 focus:outline-none"
            >
              {isMobileMenuOpen ? (
                <FaTimes size={24} />
              ) : (
                <FaBars size={24} />
              )}
            </button>
          </div>

          {/* Mobile Menu (Dropdown) */}
          <div
            className={`lg:hidden flex flex-col absolute top-0 left-0 z-20 mt-16 bg-white h-screen shadow-md rounded-lg w-full p-4 space-y-4 transition-transform duration-300 ease-in-out ${
              isMobileMenuOpen
                ? "opacity-100 translate-x-0"
                : "opacity-0 -translate-x-full pointer-events-none"
            }`}
          >
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive
                  ? "text-red-700 font-[400] text-[20px]"
                  : "text-gray-700 hover:text-red-700 font-[400] text-[20px]"
              }
              onClick={toggleMobileMenu}
            >
              Home
            </NavLink>
            <NavLink
              to="/oursolution"
              className={({ isActive }) =>
                isActive
                  ? "text-red-700 font-[400] text-[20px]"
                  : "text-gray-700 hover:text-red-700 font-[400] text-[20px]"
              }
              onClick={toggleMobileMenu}
            >
              Our Solution
            </NavLink>
            <NavLink
              to="/squarebric"
              className={({ isActive }) =>
                isActive
                  ? "text-red-700 font-[400] text-[20px]"
                  : "text-gray-700 hover:text-red-700 font-[400] text-[20px]"
              }
              onClick={toggleMobileMenu}
            >
              Our Squarebric
            </NavLink>
            <NavLink
              to="/about-us"
              className={({ isActive }) =>
                isActive
                  ? "text-red-700 font-[400] text-[20px]"
                  : "text-gray-700 hover:text-red-700 font-[400] text-[20px]"
              }
              onClick={toggleMobileMenu}
            >
              About Us
            </NavLink>
            <NavLink
              to="/contact-us"
              className={({ isActive }) =>
                isActive
                  ? "text-red-700 font-[400] text-[20px]"
                  : "text-gray-700 hover:text-red-700 font-[400] text-[20px]"
              }
              onClick={toggleMobileMenu}
            >
              Contact Us
            </NavLink>
            <NavLink
              to="/careers"
              className={({ isActive }) =>
                isActive
                  ? "text-red-700 font-[400] text-[20px]"
                  : "text-gray-700 hover:text-red-700 font-[400] text-[20px]"
              }
              onClick={toggleMobileMenu}
            >
              Careers
            </NavLink>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
