import React from "react";
import runway from "../../../assets/Runway.png"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
const timelineData = [
  {
    icon: runway,
    title: "Airports",

    description:
      "Unattended object detection identifies suspicious items like bags or luggage in terminals and checkpoints, preventing threats.",
  },
  {
    title: "Public Transportation",
    icon: "https://squarebric-media-files.squarebric.com/sivaldevops/assets/dwellicon2.png",

    description:
      "Quick detection of unattended items like bags or suitcases in stations ensures passenger safety and prevents breaches.",
  },
  {
    title: "Shopping Malls and Retail Stores",
    icon: "https://squarebric-media-files.squarebric.com/sivaldevops/assets/dwellicon3.png",

    description:
      "Detection systems monitor stores and mall entrances to spot items left behind, reducing theft and improving safety.",
  },
  {
    title: "Smart Cities and Public Spaces",
    icon: "https://squarebric-media-files.squarebric.com/sivaldevops/assets/dwellicon4.png",

    description:
      "In urban areas with crowds, unattended object detection enhances safety in parks, streets, and squares.",
  },
  {
    title: "Hospitals and Healthcare Facilities:",
    icon: "https://squarebric-media-files.squarebric.com/sivaldevops/assets/dwellicon5.png",

    description:
      "Detects unattended items in waiting areas, patient rooms, or public spaces, preventing obstructions and safety risks.",
  },
];

const Timeline = () => {
  return (
    <div className="flex flex-col w-full px-4 py-8 bg-network">
      <div className="max-w-7xl mx-auto md:p-4">
        <h1 className="text-white font-[300] text-[23px] md:text-[30px] leading-[30px] mb-8">
          Why Proactive AI POWERED GUN DETECTION SYSTEM
        </h1>
        {timelineData.map((item, index) => (
          <div key={index} className="flex flex-row items-center mt-0">
            {/* Icon Circle */}
            <div className="relative flex items-center justify-center w-20 h-20 md:w-28 md:h-28 rounded-full">
              <LazyLoadImage
                src={item.icon}
                alt={item.title}
                className="w-20 h-10 md:w-24 md:h-24 lg:w-28 lg:h-28 rounded-full transition-all duration-300 transform hover:scale-105"
              />
              {index !== timelineData.length - 1 && (
                <div className="absolute left-1/2 h-36 mt-0.5 md:mt-0 md:h-20 w-0.5 border-l-2 border-dotted border-gray-400 top-[60px] transform -translate-x-1/2"></div>
              )}
            </div>
            {/* Text Content */}
            <div className="ml-5 mt-14">
              <h3 className="md:text-xl font-semibold text-white">
                {item.title}
              </h3>
              <p className="text-md text-gray-300 text-justify">
                {item.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Timeline;
