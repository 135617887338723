// DynamicTitle.js
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function DynamicTitle() {
  const location = useLocation();

  useEffect(() => {
    const pageTitles = {
      "/": "Sival DevOps - Smart City & AI Solutions",
      "/squarebric": "Sival Devops - Squarebric ",
      "/careers": "Sival Devops-Career Opportunities ",
      "/contact-us": "Sival Devops-Contact Us",
      "/trafficmanagemnt": "Sival Devops-Traffic Management ",
      "/smokefire": "Sival Devops-Smoke and Fire Detection",
      "/facerecognition": "Sival Devops-Face Recognition",
      "/dwellanalysis": "Sival Devops-Dwell Time Analysis",
      "/intrusion": "Sival Devops-Intrusion Detection ",
      "/oursolution": "Sival Devops-Our Solutions",
      "/about-us": "Sival Devops-About Us ",
      "/crowd": "Sival Devops-Crowd Management ",
      "/unattended": "Sival Devops-Unattend Object Detection",
      "/gundetection": "Sival Devops-Gun Detection ",
      "/job-description": "Sival Devops -Job Description ",
    };

    // Update the title or use a default if the path is not defined
    document.title = pageTitles[location.pathname] || "Sival Devops";
  }, [location]);

  return null; // This component doesn't render anything visible
}

export default DynamicTitle;
