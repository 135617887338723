import React from "react";
import Untend1 from "./Untend1";
import Untend2 from "./Untend2";
import Untend3 from "./Untend3";
import Untend4 from "./Untend4";
import Untend7 from "./Untend7";
import LinkData from "../LinkData";
import Form from "../Form";
function Untend() {
  return (
    <div>
      <Untend1 />
      <Untend2 />
      <Untend3 />
      <LinkData />
      <Untend4 />
      <Untend7 />
      <Form />
    </div>
  );
}

export default Untend;
